<template>
	<div class="main">
		<div id="china_map_box" ref="mydata" :style="conheight">
			<el-button class="full" @click="isScreenFull()">全屏显示</el-button>
			<div class="title">种子追溯码跟踪监测大数据</div>
			<dv-decoration-5 class="title-s" style="width:50%;height:40px;" />
			<div class="line-box">

				<dv-border-box-1 style="text-align:center;">
					<div class="line-title">扫码数据统计</div>
					<div id="line"></div>
				</dv-border-box-1>
				<!-- <dv-border-box-11 title="扫码数据统计"></dv-border-box-11> -->

			</div>
			<div class="line-box-city">

				<dv-border-box-1 style="text-align:center;">
					<div class="line-title">省内扫码数据统计</div>
					<div id="line-city"></div>
				</dv-border-box-1>
				<!-- <dv-border-box-11 title="扫码数据统计"></dv-border-box-11> -->

			</div>
			<div class="syn">
				<!-- <dv-decoration-6 style="width:300px;height:30px;" /> -->
				<dv-border-box-1>
					<div class="sym-title">生成追溯码总数</div>
					<div class="symcount blue">{{symdata.sym}}</div>
				</dv-border-box-1>

			</div>
			<div class="sym2">
				<dv-border-box-1>
					<div class="sym-title">追溯码扫描次数</div>
					<div class="symcount red">{{symdata.smtz}}</div>
				</dv-border-box-1>

				<dv-decoration-1 style="width:200px;height:50px;" />
			</div>
			<div id="hb_map"></div>

			<div id="china_map"></div>

		</div>
	</div>
</template>

<script>
	import echarts from "echarts";
	import '../../assets/js/china';
	import '../../assets/js/hebei';
	import screenfull from "screenfull";
	export default {
		data() {
			return {
				symdata: {
					sym: 0,
					smtz: 0
				},
				refsh: 0,
				conheight: {
					height: ''

				},
				isFullscreen: false,
				citygeo: [],
				lastId: '',
				//echart 配制option  
				options: {
					tooltip: {
						triggerOn: "mousemove", //mousemove、click
						padding: 8,
						borderWidth: 1,
						borderColor: '#409eff',
						backgroundColor: 'rgba(255,255,255,0.7)',//247,241,225/

						textStyle: {
							color: '#000000',
							fontSize: 13
						},
						formatter: function(e, t, n) {
							let data = e.data;
							//模拟数据
							// data.specialImportant = Math.random()*1000 | 0;
							// data.import = Math.random()*1000 | 0;
							// data.compare = Math.random()*1000 | 0;
							// data.common = Math.random()*1000 | 0;
							// data.specail = Math.random()*1000 | 0;

							if (data) {
								let context = `
               <div>
                   <p><b style="font-size:15px;">${data.name}</b></p>
                   <p class="tooltip_style"><span class="tooltip_left">销售扫码数量</span><span class="tooltip_right">${data.value}次</span></p>
                  
               </div>
            `
								return context;
							}


						}
					},
					visualMap: {
						show: true,
						left: 500,
						bottom: 50,
						showLabel: true,
						continuous: {
							textStyle: {
								color: '#ffffff'
							}
						},
						textStyle: {
							color: '#ffffff'
						},
						pieces: [{
								gte: 100,
								label: ">= 1000",
								color: "#f44336"
							},
							{
								gte: 500,
								lt: 999,
								label: "500 - 999",
								color: "#fc9700"
							},
							{
								gte: 100,
								lt: 499,
								label: "100 - 499",
								color: "#ffde00"
							},
							{
								gte: 10,
								lt: 99,
								label: "10 - 99",
								color: "#ffde00"
							},
							{
								lt: 10,
								label: '<10',
								color: "#00eaff"
							}
						]
					},
					geo: {
						map: "china",
						scaleLimit: {
							min: 1,
							max: 2
						},
						zoom: 1,
						top: 120,
						label: {
							normal: {
								show: true,
								fontSize: "14",
								color: "rgba(0,0,0,0.7)",

							}
						},
						itemStyle: {
							normal: {
								//shadowBlur: 50,
								//shadowColor: 'rgba(0, 0, 0, 0.2)',
								areaColor: "rgb(247,241,225)",
								borderColor: "rgba(23,69,203, 0.9)"
							},
							emphasis: {
								areaColor: "rgb(247,241,225)",
								// color:"#ffffff",
								textStyle: {
									color: '#000'
								},
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								borderWidth: 0
							}
						}
					},
					series: [{
							name: "销售扫码数量",
							type: "map",
							geoIndex: 0,
							data: []
						},
						{
							// name: '贵阳市飞线',
							type: 'lines',
							coordinateSystem: 'geo',
							polyline: true,
							zlevel: 2,

							effect: {
								show: true,
								period: 5,
								trailLength: 0.5, // 拖尾
								symbol: 'arrow', // 箭头
								color: '#f44336', // 样式颜色
								symbolSize: 10,


							},
							rippleEffect: {
								period: 0.95,
								scale: 2.5,
								brushType: 'fill'
							},
							lineStyle: {
								normal: {
									color: '#f44336',
									width: 2,
									curveness: 0.9
								},
								// data: that.linesCoord,
								color: '#000',
								clip: true,
								type: 'dotted',
								dashOffset: 0,
								// cap: butt,
								join: "bevel"

							},
							// 飞线层数据
							data: []
						},



					]
				},
				hboptions: {
					tooltip: {
						triggerOn: "mousemove", //mousemove、click
						padding: 8,
						borderWidth: 1,
						borderColor: '#409eff',
						backgroundColor: 'rgba(255,255,255,0.7)',
						textStyle: {
							color: '#000000',
							fontSize: 13
						},
						formatter: function(e, t, n) {
							let data = e.data;
							//模拟数据
							// data.specialImportant = Math.random()*1000 | 0;
							// data.import = Math.random()*1000 | 0;
							// data.compare = Math.random()*1000 | 0;
							// data.common = Math.random()*1000 | 0;
							// data.specail = Math.random()*1000 | 0;

							if (data) {
								let context = `
               <div>
                   <p><b style="font-size:15px;">${data.name}</b></p>
                   <p class="tooltip_style"><span class="tooltip_left">销售扫码数量</span><span class="tooltip_right">${data.value}次</span></p>
                  
               </div>
            `
								return context;
							}


						}
					},
					visualMap: {
						show: true,
						left: 500,
						bottom: 50,
						showLabel: true,
						continuous: {
							textStyle: {
								color: '#ffffff'
							}
						},
						textStyle: {
							color: '#ffffff'
						},
						pieces: [{
								gte: 100,
								label: ">= 1000",
								color: "#f44336"
							},
							{
								gte: 500,
								lt: 999,
								label: "500 - 999",
								color: "#fc9700"
							},
							{
								gte: 100,
								lt: 499,
								label: "100 - 499",
								color: "#ffde00"
							},
							{
								gte: 10,
								lt: 99,
								label: "10 - 99",
								color: "#ffde00"
							},
							{
								lt: 10,
								label: '<10',
								color: "#00eaff"
							}
						]
					},
					geo: {
						map: "河北",
						scaleLimit: {
							min: 1,
							max: 2
						},
						zoom: 1,
						top: 120,
						label: {
							normal: {
								show: true,
								fontSize: "14",
								color: "rgba(255,0,0,0.7)",

							}
						},
						itemStyle: {
							normal: {
								//shadowBlur: 50,
								//shadowColor: 'rgba(0, 0, 0, 0.2)',
								areaColor: "rgb(247,241,225)",
								borderColor: "rgba(23,69,203, 0.9)"
							},
							emphasis: {
								areaColor: "rgb(22,46,110)",
								// color:"#ffffff",
								textStyle: {
									color: '#fff'
								},
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								borderWidth: 0
							}
						}
					},
					series: [{
							name: "销售扫码数量",
							type: "map",
							geoIndex: 0,
							data: []
						},
						{
							// name: '贵阳市飞线',
							type: 'lines',
							coordinateSystem: 'geo',
							polyline: true,
							zlevel: 2,

							effect: {
								show: true,
								period: 5,
								trailLength: 0.5, // 拖尾
								symbol: 'arrow', // 箭头
								color: 'rgb(0,255,13)', // 样式颜色
								symbolSize: 10,


							},
							rippleEffect: {
								period: 0.95,
								scale: 2.5,
								brushType: 'fill'
							},
							lineStyle: {
								normal: {
									color: 'rgb(0,255,13)',
									width: 2,
									curveness: 0.9
								},
								// data: that.linesCoord,
								color: '#000',
								clip: true,
								type: 'dotted',
								dashOffset: 0,
								// cap: butt,
								join: "bevel"

							},
							// 飞线层数据
							data: []
						},



					]
				},
				//echart data
				dataList: [

				],
				hbdataList: []
			};
		},
		methods: {
			getHeight() {
				this.conheight.height = window.innerHeight + 'px';
				console.log(this.conheight.height)
			},

			isScreenFull() {
				// 需要注意的是 如果判断!screenfull.enabled 显示的是不支持全屏的话 是因为谷歌的版本问题  判断改为 !screenfull.isEnabled  就可以了

				if (!screenfull.isEnabled) {
					// 如果不支持进入全屏，发出不支持提示
					this.$message({
						message: "您的浏览器版本过低不支持全屏显示！",
						type: "warning"
					});
					return false;
				}
				screenfull.toggle(this.$refs.mydata);
			},
			//初始化中国地图
			initEchartMap() {
				let mapDiv = document.getElementById('china_map');
				let myChart = echarts.init(mapDiv);
				myChart.setOption(this.options);
			},
			initEcharthbMap() {
				let hbDiv = document.getElementById('hb_map');
				let hbChart = echarts.init(hbDiv);
				hbChart.setOption(this.hboptions);
			},
			//第一个柱状图
			linecate() {
				let xdata = []
				let ydata = []
				this.dataList.forEach(item => {
					xdata.push(item.name)
					ydata.push(item.value)
				});
				console.log(ydata)

				let option = {
					legend: {
						textStyle: {
							fontSize: 15,
							color: '#fff'
						}
					},
					xAxis: {
						type: 'category',
						data: xdata,
						splitLine: {
							show: false
						},
						axisLine: {
							lineStyle: {
								type: 'solid',
								color: 'rgb(74,204,150)', //左边线的颜色
								width: '2' //坐标线的宽度
							}
						},
						axisLabel: {
							textStyle: {
								color: "#ffffff"
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: false
						},
						axisLine: {
							lineStyle: {
								type: 'solid',
								color: 'rgb(74,204,150)', //左边线的颜色
								width: '2' //坐标线的宽度
							}
						},
					},
					series: [{
						data: ydata,
						type: 'bar',
						barWidth: 30,
						itemStyle: {
							normal: {
								color: 'rgb(74,204,150)',
								barBorderRadius: [5, 5, 0, 0]
							}
						}

					}]
				};
				let catDiv = document.getElementById('line');
				let catChart = echarts.init(catDiv);
				catChart.setOption(option);

			},
			linecatecity() {
				let xdata = []
				let ydata = []
				this.hbdataList.forEach(item => {
					xdata.push(item.name)
					ydata.push(item.value)
				});
				console.log(ydata)

				let cityoption = {
					legend: {
						textStyle: {
							fontSize: 15,
							color: '#fff'
						}
					},
					xAxis: {
						type: 'category',
						data: xdata,
						splitLine: {
							show: false
						},
						axisLine: {
							lineStyle: {
								type: 'solid',
								color: 'rgb(74,204,150)', //左边线的颜色
								width: '2' //坐标线的宽度
							}
						},
						axisLabel: {
							textStyle: {
								color: "#ffffff"
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: false
						},
						axisLine: {
							lineStyle: {
								type: 'solid',
								color: 'rgb(74,204,150)', //左边线的颜色
								width: '2' //坐标线的宽度
							}
						},
					},
					series: [{
						data: ydata,
						type: 'bar',
						barWidth: 30,
						itemStyle: {
							normal: {
								color: 'rgb(74,204,150)',
								barBorderRadius: [5, 5, 0, 0]
							}
						}

					}]
				};
				let cityDiv = document.getElementById('line-city');
				let cityChart = echarts.init(cityDiv);
				cityChart.setOption(cityoption);

			},
			//修改echart配制
			async GovSmtz() {
				const {
					data: res
				} = await this.$http.post("GovSmtz");

				var city = []
				res.data.forEach(item => {
					city.push({
						"name": item.name.substr(0, item.name.length - 1),
						"value": item.value
					})
				});

				this.dataList = city

				this.setEchartOption();

				this.$nextTick(() => {
					this.initEchartMap();
					this.initEcharthbMap();
					this.linecate();

				})
			},
			async gethebei() {
				var hebei = []
				const {
					data: res2
				} = await this.$http.post("getHeBei");
				res2.data.forEach(item2 => {
					hebei.push({
						"name": item2.name,
						"value": item2.value
					})

				});
				this.hbdataList = hebei;
				console.log(this.hbdataList)
				this.setEchartOptionhb();
				this.$nextTick(() => {

					this.initEcharthbMap();
					this.linecatecity();
				})
			},
			setEchartOption() {
				console.log(this.dataList)
				this.options.series[0]['data'] = this.dataList;
			},
			setEchartOptionhb() {
				console.log(this.hbdataList)
				this.hboptions.series[0]['data'] = this.hbdataList;
			},
			feixian() {
				this.citygeo.push([
					[114.48, 38.03],
					[117.25, 31.83]
				])
				this.options.series[1]['data'] = this.citygeo;
				this.$nextTick(() => {
					this.initEchartMap();
				})
			},
			async getLastId() {

				const {
					data: res
				} = await this.$http.post("getLastId", );
				const {
					data: res2
				} = await this.$http.post("getsymcount", );
				this.symdata.sym = res2.data.sym;
				this.symdata.smtz = res2.data.smtz;
				if (!this.lastId) {
					this.lastId = res.data[0].id

				} else {
					if (this.lastId != res.data[0].id) {
						this.lastId = res.data[0].id;
						this.citygeo = [
							[
								[res.data[0].lng, res.data[0].lat],
								[114.48, 38.03]
							]
						];
						this.options.series[1]["data"] = this.citygeo;
						this.$nextTick(() => {
							this.initEchartMap();
						});
						this.refsh = 1
					} else {
						this.citygeo = [];
						this.options.series[1]["data"] = this.citygeo;
						if (this.refsh == 1) {
							this.$nextTick(() => {
								this.initEchartMap();
							});
							this.refsh = 0;
						}

					}

				}


			}
		},
		created() {
			window.addEventListener('resize', this.getHeight);
			this.getHeight()
			this.GovSmtz();
			this.gethebei();

			// this.getLastId();


		},
		mounted() {
			window.setInterval(() => {
				setTimeout(this.getLastId(), 0);
			}, 3000);
			// this.linecate();
			//  this.$nextTick(()=>{
			//      this.linecate();
			//   })



		}
	};
</script>

<style lang="less" scoped>
	canvas {
		width: 100% !important;
	}

	.main {
		background-image: url("../../assets/img/map_bg.jpg");
		/* background-color: rgb(0,29,142); */
		background-size: 100% 100%;
	}

	#china_map_box {
		height: 100%;
		position: relative;
		/* background-image: url("../../assets/img/bg.png"); */
		background-image: url("../../assets/img/map_bg.jpg");
		background-size: 100% 100%;
	}

	#china_map_box #china_map {
		/* position: absolute; */
		height: 80%;
		width: 80%;
		left: 200px;

	}

	#china_map_box .china_map_logo {
		position: absolute;
		top: 0;
		left: 0;
		width: 45px;
	}

	#line {
		/* position: absolute; */
		height: 100%;
		width: 500px;
	}

	#line-city {
		/* position: absolute; */
		height: 100%;
		width: 500px;
	}

	.title {
		width: 100%;
		position: absolute;
		font-family: 华文新魏 !important;
		color: #fff;
		font-size: 60px;
		font-weight: 300;
		margin: 5px 0 28px 0;
		text-align: center;
	}

	.title-s {
		margin: 0 auto;
		/* position: absolute; */
		padding-top: 50px;
		height: 50px;
	}

	.line-box {
		position: absolute;
		bottom: 100px;
		left: 20px;
		height: 370px;
		width: 500px;
		/* background-color: rgba(6, 17, 63, .9); */
		;


	}

	.line-box-city {
		position: absolute;
		top: 80px;
		left: 20px;
		height: 350px;
		width: 500px;
		/* background-color: rgba(6, 17, 63, .9); */
		;
	}

	.syn {
		position: absolute;
		width: 450px;
		height: 120px;
		right: 50px;
		top: 100px;
		line-height: 120px;
		/* background-color: rgba(6, 17, 63, .9); */
		;
		text-align: center;

	}

	.sym2 {
		position: absolute;
		width: 450px;
		height: 120px;
		right: 50px;
		top: 250px;
		line-height: 120px;
		/* background-color: rgba(6, 17, 63, .9); */
		text-align: center;
	}

	.sym-title {
		display: inline;
		font-family: led;
		color: #fff;
		/* color: rgb(94, 107, 202); */
		font-size: 23px;
	}

	.symcount {
		display: inline;
		/* position: absolute; */
		font-family: led;

		color: rgb(79, 246, 249);
		
		font-size: 70px;
	}
	.blue{
		background: linear-gradient(#0cebeb, #20e3b2,#29ffc6);
		-webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
	}
	.red{
		background: linear-gradient(#ff4b1f,#ff9068);
		-webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
	}
	.full {
		position: absolute;
		z-index: 1000;
	}

	#hb_map {
		position: absolute;
		height: 550px;
		width: 400px;
		bottom: 60px;
		right: 0px;
		z-index: 999;
	}
</style>
<style>
	#china_map .tooltip_style {
		line-height: 1.7;
		overflow: hidden;
	}

	#china_map .tooltip_left {
		float: left;
	}

	#china_map .tooltip_right {
		float: right;
	}

	.line-title {
		display: inline-block;
		color: #fff;
		font-size: 25px;
		font-weight: 600;
		padding-top: 10px;
		padding-left: 10px;
		border-bottom: 1px solid #0258f0;
		box-sizing: border-box;
	}
</style>
